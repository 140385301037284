<template>
  <!-- 我的 -->
  <div class="message">
    <van-image v-if="banner.picUrl" class="head-banner" :src="banner.picUrl" @click.native="handleClickBanner"/>

    <van-empty v-if="messageList.length===0" description="暂无新消息"/>

    <van-cell-group v-if="messageList.length>0">
      <van-list class="circleList" v-model="loading" :finished="finished" @load="onLoad">
        <van-cell v-for="item in messageList"
                  :key="item.id" center is-link value-class="time-info" @click.native="handleMessage(item)"
                  :icon="item.headimgUrl" :title="item.nickName" :value="getDataDesc(item.createTime)"
                  :label="htmlToString(item.content)">
          <template #icon>
            <van-badge :dot="item.status===0" style="margin-right: 11px">
              <van-image
                round
                width="44px"
                height="44px"
                fit="cover"
                :src="item.headimgUrl ? item.headimgUrl : 'https://static.haoqute.com/upload/2022/10/12/36b29ac6-3b25-4523-b983-263b47e444d9.jpeg'"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20"/>
                </template>
              </van-image>
            </van-badge>
          </template>
        </van-cell>
      </van-list>
    </van-cell-group>

    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="wrapper-content">
          <span style="font-size: 20px;font-weight: bold">提示</span>
          <span class="message-tip">我们希望通过『好求HaoQute』公众号为您推送活动发布、评论回复、审核打款等消息提醒。</span>

          <wx-open-subscribe
            template="fCde00563Q4LJ2fqb1GQkidS7UiQUUgdYt7on3KUE60,YLvRf69tLVJne9wzMO6cm0JImLE0YmLzovhNoJeqT10,zSVxBuJAak1P30_qYT5POB5WGB4zhPK-9ZHvLAqXuR4"
            id="subscribe-btn">
            <script type="text/wxtag-template" slot="style">
              <style>
                  .subscribe-btn {
                      width: 144px;
                      height: 44px;
                      border: 0;
                      border-radius: 6px;

                      color: #fff;
                      background-color: #3E639A;
                      margin: 11px;
                  }
              </style>
            </script>
            <script type="text/wxtag-template">
              <button class="subscribe-btn">
                立即订阅
              </button>
            </script>
          </wx-open-subscribe>
        </div>

        <van-icon name="close" style="margin: 16px" color="#c9c9c9" @click="clickedSubscribe"/>
      </div>
    </van-overlay>
  </div>
</template>

<script>
  import untils from "@/until/until"
  import wxLogin from '@/until/wxLogin.js'
  import wx from "weixin-js-sdk";

  import Vue from 'vue';
  import {Cell, CellGroup, Image as VanImage} from 'vant';
  import {getDateDiff} from "@/until/date";

  Vue.use(Cell).use(VanImage).use(CellGroup);

  export default {
    name: "message",
    data() {
      return {
        show: false,
        messageList: [],
        logo: require('@/assets/img/my/buzzlogo@2x.png'),
        banner: {},
        loading: false,
        finished: false,
        pageNum: 1,
        pageSize: 20
      }
    },
    async mounted() {
      // 本次打开已经弹过 or 非微信内，不弹提示
      this.show = sessionStorage.getItem('showMessageSubscribe') !== 'true' && untils.isWechat()

      if (!localStorage.sessionKey && untils.isWechat()) {
        const res = await wxLogin.getWechatAppId()
        await wxLogin.wxLogin(window.location.href, res.data.appId)
      } else {
        let UserInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        if (!UserInfo.phone || UserInfo.phone === null || UserInfo.phone === '') {
          // this.$router.push('/login?type=2')
          if (untils.isWechat()) {
            const res = await wxLogin.getWechatAppId()
            await wxLogin.wxLogin(window.location.href, res.data.appId)
          }
        }
      }
      this.getMessageList(true)
      this.getBanner()
      // wx js sdk ready 后进行事件监听
      wx.ready(() => {
        let btn = document.getElementById('subscribe-btn');
        let _this = this
        btn.addEventListener('success', function (e) {
          _this.clickedSubscribe()
        });
        btn.addEventListener('error', function (e) {
          _this.clickedSubscribe()
        });
      })
    },
    methods: {
      getDataDesc(dateStr){
        return getDateDiff(dateStr)
      },
      htmlToString(htmlStr){
        return htmlStr.replace(/<\/?.+?\/?>|\r|\n|\s*/g,'')
      },
      clickedSubscribe() {
        this.show = false
        sessionStorage.setItem('showMessageSubscribe', 'true')
      },
      handleClickBanner(){
        if (this.banner.links){
          location.href = this.banner.links
        }
      },
      getBanner() {
        this.$api.home.bannerList({
          type: 3
        }, res => {
          if (res.code == 200) {
            this.banner = res.rows[0]
          }
        })
      },
      getMessageList(refresh) {
        this.loading = true
        if (refresh) {
          this.pageNum = 1
        }else {
          this.pageNum++
        }
        this.$api.message.getMessageList({"pageNum": this.pageNum, "pageSize": this.pageSize},
          res => {
            if (res.code === 200){
              this.loading = false
              if (res.rows.length === 0){
                this.finished = true
                return
              }

              this.messageList = this.messageList.concat(res.rows)
            }else {
              this.loading = false
            }
          })
      },
      onLoad() {
        this.getMessageList(false)
      },
      handleMessage(e) {
        this.$api.message.setMessageRead({'id': e.id}, res => {})

        if (e.type === 0) {
          // 问答活动提醒
          location.href = e.url
        } else if (e.type === 1) {
          // 问卷活动提醒
          location.href = e.url
        } else if (e.type === 2) {
          // 追问提醒
          // this.$router.push({path: '/activity/details?id=' + e.params.id})
          sessionStorage.setItem('scrollByOffsetTop', 'true')
          location.href = e.url + '&fromMessage=true'
        } else if (e.type === 3 || e.type === 4) {
          // 审核通过, 微信打款到账
          this.$router.push({path: '/my/income/list'})
        } else if (e.type === 5){
          // 审核驳回，问卷跳问卷列表；问答跳活动详情
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .message {
    width: 100%;
    background-color: #ffffff;
    min-height: 100vh;
    position: relative;
    padding: 28px 30px 30px;

    .van-cell {
      padding-left: 6px;
      padding-right: 6px;

      .van-cell__title {
        min-width: 55%;
        text-align: left;
        overflow:hidden;
        max-lines: 2;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
      }

      .van-cell__value {
        min-width: 20%;
      }
    }

    .head-banner {
      width: 690px;
      height: 240px;
      object-fit: cover;
      border-radius: 15px;
      margin-bottom: 6px;
    }

    .time-info {
      font-size: 16px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .wrapper-content {
      display: flex;
      flex-direction: column;

      width: 600px;
      height: auto;
      min-height: 200px;
      background-color: #fff;
      border-radius: 16px;
      padding: 16px;
      align-items: center;
      text-align: center;
    }

    .message-tip {
      font-size: 32px;
      margin: 11px 0;
    }
  }
</style>
